// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export default () => {
  // window.dataLayer = window.dataLayer || []
  // window.onRecaptchaJSLibraryLoad = function () {
  //   if (typeof window.grecaptcha !== `undefined`) {
  //     try {
  //       window?.grecaptcha
  //         ?.execute(`6LdOq8QUAAAAADPLiZ9hM-LYbi4_fr0Ox8BV2iVs`)
  //         ?.then(function (token) {
  //           if (
  //             document.getElementById(`mauticform_input_trialrequest_recaptcha`)
  //           ) {
  //             document.getElementById(
  //               `mauticform_input_trialrequest_recaptcha`
  //             ).value = token
  //           }
  //         })
  //     } catch (error) {
  //       console.log("🚀 ~ error:", error)
  //     }
  //   }
  // }
  // window.onRecaptchaJSLibraryLoad()

  // window.onRecaptchaJSLibraryLoadModal = function () {
  //   var modalMauticFormName = document.querySelector(".pux-modal-content .mauticform_wrapper form")?.getAttribute("data-mautic-form");
  //   if (typeof window.grecaptcha !== `undefined` && modalMauticFormName) {
  //     window.grecaptcha
  //       .execute('6LdOq8QUAAAAADPLiZ9hM-LYbi4_fr0Ox8BV2iVs')
  //       .then(function (token) {
  //         document.getElementById("mauticform_input_" + modalMauticFormName + "_recaptcha").value = token;
  //       });
  //   }
  // }

  // function IDGenerator() {
  //   this.length = 77
  //   this.timestamp = +new Date()

  //   const _getRandomInt = function (min, max) {
  //     return Math.floor(Math.random() * (max - min + 1)) + min
  //   }

  //   this.generate = function () {
  //     const ts = this.timestamp.toString()
  //     const parts = ts.split(``).reverse()
  //     let id = ``

  //     for (let i = 0; i < this.length; ++i) {
  //       const index = _getRandomInt(0, parts.length - 1)
  //       id += parts[index]
  //     }
  //     return id
  //   }
  // }

  // function loadReCaptcha() {
  //   if (typeof window.grecaptcha == 'undefined') {
  //     var head = document.getElementsByTagName('head')[0];
  //     var script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaJSLibraryLoad&render=6LdOq8QUAAAAADPLiZ9hM-LYbi4_fr0Ox8BV2iVs';
  //     head.appendChild(script);
  //   }
  // }

  // document.getElementById("mauticform_trialrequest")?.addEventListener("click", function () {
  //   if (typeof window.grecaptcha == 'undefined') {
  //     loadReCaptcha();
  //   }
  // });

  // if (typeof window.MauticFormCallback == `undefined`) {
  //   window.MauticFormCallback = {}
  // }

  // window.MauticFormCallback[`trialrequest`] = {
  //   onValidate: function (formValid) {
  //     // before form submit
  //     window.generator = new IDGenerator()
  //     window.uniqid = window.generator.generate()
  //     if (document.getElementById(`mauticform_input_trialrequest_identifier`)) {
  //       document.getElementById(
  //         `mauticform_input_trialrequest_identifier`
  //       ).value = window.uniqid
  //     }
  //     // validate reCaptcha
  //     if (typeof window.grecaptcha == `undefined`) {
  //       window.loadReCaptcha();
  //     } else {
  //       if (typeof window.grecaptcha !== `undefined`) {
  //         window.grecaptcha
  //           .execute(`6LdOq8QUAAAAADPLiZ9hM-LYbi4_fr0Ox8BV2iVs`)
  //           .then(function (token) {
  //             if (
  //               document.getElementById(
  //                 `mauticform_input_trialrequest_recaptcha`
  //               )
  //             ) {
  //               document.getElementById(
  //                 `mauticform_input_trialrequest_recaptcha`
  //               ).value = token
  //             }
  //           })
  //       }
  //     }
  //     if (document.getElementById("mauticform_input_trialrequest_recaptcha")) {
  //       if (document.getElementById("mauticform_input_trialrequest_recaptcha").value == "") {
  //         // console.log("no recaptcha value, try again later");
  //         setTimeout(function () {
  //           if (document.getElementById("mauticform_input_trialrequest_submit")) {
  //             document.getElementById("mauticform_input_trialrequest_submit").click();
  //           }
  //         }, 500);
  //         return false;
  //       }
  //     }
  //     return null;
  //   },
  //   onResponse: function (response) {
  //     if (response.success) {
  //       if (document.getElementById("mauticform_input_trialrequest_email")) {
  //         document.getElementById("mauticform_input_trialrequest_email").disabled = true;
  //         document.getElementById("mauticform_input_trialrequest_email").style.background = "#dde0ee";
  //       }

  //       if (document.getElementById("mauticform_input_trialrequest_submit")) {
  //         document.getElementById("mauticform_input_trialrequest_submit").disabled = true;
  //         document.getElementById("mauticform_input_trialrequest_submit").style.background = "#667088";
  //         document.getElementById("mauticform_input_trialrequest_submit").textContent = MauticLang.submittingMessage;
  //       }

  //       if (document.getElementById("mauticform_trialrequest_error")) {
  //         document.getElementById("mauticform_trialrequest_error").textContent = "";
  //       }

  //       var langtag = document.documentElement.lang.split("-")[0];

  //       // after form submit
  //       if (
  //         typeof window.MauticFormCallback[`trialrequest`][`onResponseRun`] ==
  //         `undefined`
  //       ) {
  //         window.MauticFormCallback[`trialrequest`][`onResponseRun`] = 1

  //         var trialtype = `-`
  //         if (
  //           document.getElementById(
  //             `mauticform_input_trialrequest_forced_project_id_2`
  //           ) &&
  //           document.getElementById(
  //             `mauticform_input_trialrequest_forced_project_id_2`
  //           ).checked
  //         ) {
  //           trialtype = `Full demo`
  //         }
  //         if (
  //           document.getElementById(
  //             `mauticform_input_trialrequest_forced_project_id_1`
  //           ) &&
  //           document.getElementById(
  //             `mauticform_input_trialrequest_forced_project_id_1`
  //           ).checked
  //         ) {
  //           trialtype = `Lite demo`
  //         }
  //         window.dataLayer.push({
  //           'event': 'gaVirtualPageview',
  //           'gaVirtualPageviewUrl': '/virtual/Trial/01-Trial-form-submitted',
  //           'gaVirtualPageviewTitle': 'Virtual Trial/01-Trial-form-submitted',
  //           'eventCallback': function (containerId) {
  //             if (containerId.includes("GTM-")) {
  //               window.dataLayer.push({
  //                 'event': 'gaEvent',
  //                 'gaEventData': {
  //                   'eventCategory': 'Trial',
  //                   'eventAction': '01-Trial-form-submitted',
  //                   'eventLabel': trialtype,
  //                   'nonInteraction': false
  //                 },
  //                 'eventCallback': function () {
  //                   dataLayer.push({ 'gaEventData': undefined });
  //                   // redirect to lobby
  //                   setTimeout(function () { window.location.replace("https://lobby.easysoftware.com/trial/" + window.uniqid + "?brand=easyredmine.com&lang=" + langtag); }, 1000);
  //                 }
  //               });
  //             }
  //           }
  //         });
  //         // redirect to lobby
  //         setTimeout(
  //           window.location.replace(
  //             `https://lobby.easysoftware.com/trial/` +
  //             window.uniqid +
  //             `?brand=easyredmine.com&lang=` +
  //             langtag
  //           ),
  //           4000
  //         )
  //       }
  //     } else if (typeof response.validationErrors !== "undefined") {
  //       if (typeof response.validationErrors.recaptcha !== "undefined") {
  //         if (document.getElementById("mauticform_trialrequest_error")) {
  //           document.getElementById("mauticform_trialrequest_error").textContent = response.validationErrors.recaptcha;
  //         }
  //       }
  //     }
  //   }
  // }
}
