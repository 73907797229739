import React, { FunctionComponent, Suspense, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'

import { getLocalizedData } from '../../utils/localeURL'
import FormFieldCheckboxWidget from '../PuxForm/FormFieldInputCheckboxWidget'
// import FormFieldFileWidget from '../PuxForm/FormFieldInputFileWidget'
import FormFieldInputWidget from '../PuxForm/FormFieldInputWidget'
import FormFieldMultipleWidget from '../PuxForm/FormFieldMultipleWidget'
import FormFieldRadioWidget from '../PuxForm/FormFieldRadioWidget'
import FormFieldSubmitWidget from '../PuxForm/FormFieldSubmitWidget'
import FormFieldTextareaWidget from '../PuxForm/FormFieldTextareaWidget'
import FormFieldTextWidget from '../PuxForm/FormFieldTextWidget'
import { ContentLinkType } from '../PuxNavigation/PuxNavigation'
import { getFormData, sendFormData } from './puxFormHelper'
import * as WidgetBuilder from './WidgetBuilder'
import { WidgetBase } from './WidgetBuilder'
import { WidgetContainerProps } from './WidgetContainer'

export interface FormElementValidion {
  formElementValidationRequired: boolean
  formElementValidationPattern?: string
  formElementValidationMin?: number
  formElementValidationMax?: number
  formElementValidationMinLength?: number
  formElementValidationMaxLength?: number
}

export interface FormElementOptions {
  formElementOptionsDisabled: boolean
  formElementOptionsAutofocus: boolean
  formElementOptionsAutocomplete: boolean
  formElementOptionsMultiple: boolean
  formElementOptionsStep: number
  formElementOptionsCssClass: string
  formElementOptionsReadOnly: boolean
}

export interface FormElement {
  formElementDefaultValue: string
  formElementID: string
  formElementLabel: string
  formElementName: string
  formElementPlaceholder: string
}

export interface FormOptions {
  formAction: string
  formMethod: string
  formEncType: string
  formAntiForgeryToken: boolean
  formID: string
  formSuccessMessage: HtmlBody
  formCustomCssClass: string
  formShowLabels: boolean
  formRedirectUrl: ContentLinkType
}

export interface FormRecaptchaOptions {
  formRecaptchaSiteKey: string
  formValidateRecaptcha: boolean
}

export interface Metadata {
  alignment: string
  size: number
}

export interface FormFieldTextContext {
  html: string
}

export interface HtmlBody {
  html: string
}

// types

interface BaseFieldInput {
  displayText: string
  contentType: string
}

export interface FormFieldInputType extends BaseFieldInput {
  puxFormElement: FormElement
  puxFormElementOptions: FormElementOptions
  puxFormElementValidation: FormElementValidion
  metadata: Metadata
  inputType: string
}

export interface FormFieldTextareaType extends BaseFieldInput {
  displayText: string
  contentType: string
  metadata: Metadata
  puxFormElement: FormElement
  puxFormElementOptions: FormElementOptions
  puxFormElementValidation: FormElementValidion
  textareaResize: boolean
  textareaRows: number
}

export interface FormFieldSubmitType extends BaseFieldInput {
  displayText: string
  formElementName: string
  buttonColor: string
  buttonSize: string
  buttonType: string
  metadata: Metadata
}

export interface FormFieldText extends BaseFieldInput {
  displayText: string
  formFieldTextContext: FormFieldTextContext
  metadata: Metadata
  puxFormElementOptions: FormElementOptions
}

export interface FormFieldMultipleType extends BaseFieldInput {
  displayText: string
  metadata: Metadata
  multipleOptions: string
  multipleType: string
  puxFormElement: FormElement
  puxFormElementOptions: FormElementOptions
  puxFormElementValidation: FormElementValidion
}

export type FormFieldType =
  | FormFieldInputType
  | FormFieldMultipleType
  | FormFieldSubmitType
  | FormFieldText
  | FormFieldTextareaType

// widget
export interface PuxFormType extends WidgetBase {
  contentType: 'PuxForm'
  displayText: string
  puxFormRecaptchaOptions: FormRecaptchaOptions
  puxFormOptions: FormOptions
  metadata: Metadata
  puxWidgetProperties: WidgetBuilder.WidgetProperitesPart
  puxWidgetAnimation: WidgetBuilder.WidgetAnimationPart
  flow: {
    widgets: FormFieldType[]
  }
  mautic?: {
    formContainerMauticID: string | null
    formContainerSource: string | null
    formContainerSubmissionID: string | null
  }
}

export interface PuxFormContainerType {
  contentType: 'PuxFormContainer'
  formContainerMauticID: string
  formContainerSource: string
  formContainerSubmissionID: string
  formContainerSelector: {
    contentItemIds: string[]
  }

}

export interface FormBuilderProps extends WidgetContainerProps {
  formBuilderData?: PuxFormType
}

const FormBuilder: FunctionComponent<FormBuilderProps> = (
  props: FormBuilderProps
) => {
  const formBuilderData = props?.formBuilderData?.flow?.widgets ?? []
  const mauticData = props.formBuilderData?.mautic

  const [loading, setLoading] = useState<boolean>(false)
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [beErrors, setBeErrors] = useState<string[]>(undefined as never)
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const isTrialForm = props?.formBuilderData?.displayText === "mauticform_trialrequest"

  const useRecaptcha =
    props?.formBuilderData?.puxFormRecaptchaOptions?.formValidateRecaptcha

  const myCustomOptions = formBuilderData.reduce((defaults, field) => {
    switch (field.contentType) {
      case `PuxFormFieldInput`:
      case `PuxFormFieldTextarea`:
        const fieldData = field as FormFieldInputType
        defaults[fieldData.puxFormElement.formElementName] =
          fieldData.puxFormElement.formElementDefaultValue
        return defaults
      case `PuxFormFieldMultiple`:
        const fieldDataMultiple = field as FormFieldMultipleType
        defaults[fieldDataMultiple.puxFormElement.formElementName] =
          fieldDataMultiple.puxFormElement.formElementDefaultValue
        return defaults
      default:
        return defaults
    }
  }, {})

  const methods = useForm({ defaultValues: myCustomOptions })

  const generateMauticFieldData = (value: string, id: string, label: string): FormFieldInputType => {
    return {
      puxFormElement: {
        formElementDefaultValue: value,
        formElementID: id,
        formElementLabel: label,
        formElementName: id,
        formElementPlaceholder: ''
      },
      puxFormElementOptions: {
        formElementOptionsDisabled: false,
        formElementOptionsAutofocus: false,
        formElementOptionsAutocomplete: false,
        formElementOptionsMultiple: false,
        formElementOptionsStep: 0,
        formElementOptionsCssClass: '',
        formElementOptionsReadOnly: false
      },
      puxFormElementValidation: {
        formElementValidationRequired: true,
        formElementValidationPattern: undefined,
        formElementValidationMin: undefined,
        formElementValidationMax: undefined,
        formElementValidationMinLength: undefined,
        formElementValidationMaxLength: undefined
      },
      metadata: {
        alignment: '',
        size: 100
      },
      inputType: 'hidden',
      displayText: label,
      contentType: 'PuxFormFieldInput'
    }
  }

  const buildMautic = (mauticFields: typeof mauticData, idx: number): JSX.Element | undefined | null => {
    if (!mauticFields?.formContainerMauticID || !mauticFields?.formContainerSource || !mauticFields?.formContainerSubmissionID) {
      return null
    }

    const mauticIdField = generateMauticFieldData(mauticFields.formContainerMauticID, 'mauticform[formId]', 'Mautic Id')
    const sourceField = generateMauticFieldData(mauticFields.formContainerSource, 'mauticform[source]', 'Source')
    const submissionIdField = generateMauticFieldData(mauticFields.formContainerSubmissionID, 'mauticform[submission_form_identifier]', 'Submission Id')
    const messengerField = generateMauticFieldData('1', 'mauticform[messenger]', 'Messenger')

    return (
      <>
        {buildForm(mauticIdField, idx + 1)}
        {buildForm(sourceField, idx + 2)}
        {buildForm(submissionIdField, idx + 3)}
        {buildForm(messengerField, idx + 4)}
      </>
    )
  }

  const buildForm = (
    formField: FormFieldType,
    id: number
  ): JSX.Element | undefined => {
    switch (formField.contentType) {
      case `PuxFormFieldInput`:
        const inputProps = formField as FormFieldInputType

        switch (inputProps?.inputType) {
          case `checkbox`: {
            return (
              <Suspense key={id} fallback={<></>}>
                <FormFieldCheckboxWidget
                  props={inputProps}
                  widgetProperties={props?.formBuilderData?.puxWidgetProperties}
                />
              </Suspense>
            )
          }
          case `file`: {
            return undefined
            // return (
            //   <Suspense key={id} fallback={<></>}>
            //     <FormFieldFileWidget
            //       props={inputProps}
            //       widgetProperties={props?.formBuilderData?.puxWidgetProperties}
            //     />
            //   </Suspense>
            // )
          }
          default:
            return (
              <Suspense key={id} fallback={<></>}>
                <FormFieldInputWidget
                  props={inputProps}
                  widgetProperties={props?.formBuilderData?.puxWidgetProperties}
                />
              </Suspense>
            )
        }

      case `PuxFormFieldTextarea`:
        return (
          <Suspense key={id} fallback={<></>}>
            <FormFieldTextareaWidget
              props={formField as FormFieldTextareaType}
              widgetProperties={props?.formBuilderData?.puxWidgetProperties}
            />
          </Suspense>
        )

      case `PuxFormFieldSubmit`:
        return (
          <Suspense key={id} fallback={<></>}>
            <FormFieldSubmitWidget
              props={formField as FormFieldSubmitType}
              widgetProperties={props?.formBuilderData?.puxWidgetProperties}
            />
          </Suspense>
        )
      case `PuxFormFieldText`:
        return (
          <Suspense key={id} fallback={<></>}>
            <FormFieldTextWidget
              props={formField as FormFieldText}
              widgetProperties={props?.formBuilderData?.puxWidgetProperties}
            />
          </Suspense>
        )
      case `PuxFormFieldMultiple`:
        const multipleProps = formField as FormFieldMultipleType
        return multipleProps.multipleType === `select` ? (
          <Suspense key={id} fallback={<></>}>
            <FormFieldMultipleWidget
              props={multipleProps}
              widgetProperties={props?.formBuilderData?.puxWidgetProperties}
            />
          </Suspense>
        ) : (
          <Suspense key={id} fallback={<></>}>
            <FormFieldRadioWidget
              props={multipleProps}
              widgetProperties={props?.formBuilderData?.puxWidgetProperties}
            />
          </Suspense>
        )
      default:
        return <div key={id}>{JSON.stringify(formField, null, `\n`)}</div>
    }
  }

  async function handleFormSubmit(fieldValues: FieldValues) {
    const token = useRecaptcha
      ? await recaptchaRef.current.executeAsync()
      : null
    if (useRecaptcha && !token) {
      setBeErrors([`Invalid recaptcha`])
      return
    }

    setLoading(true)
    const data = getFormData(fieldValues)
    data.append(
      `FormSystemId`,
      props?.formBuilderData?.puxFormOptions?.formID ?? ``
    )
    data.append(
      `FormCulture`,
      getLocalizedData(process.env.LOCALE ?? `en`, process.env.PROJECT!).isoCode
    )
    if (useRecaptcha) {
      data.append(`g-recaptcha-response`, token)
    }
    try {
      const responseJson = await sendFormData(
        (process.env.GATSBY_CMS_URL! +
          props?.formBuilderData?.puxFormOptions.formAction) as string,
        data,
        ``
      )

      if (responseJson) {
        if (responseJson.status == `ok`) {
          if (isTrialForm) {
            const trialId = fieldValues.mauticform.identifier
            window.dataLayer.push({
              'event': 'gaVirtualPageview',
              'gaVirtualPageviewUrl': '/virtual/Trial/01-Trial-form-submitted',
              'gaVirtualPageviewTitle': 'Virtual Trial/01-Trial-form-submitted',
              'eventCallback': function (containerId) {
                if (containerId.includes("GTM-")) {
                  window.dataLayer.push({
                    'event': 'gaEvent',
                    'gaEventData': {
                      'eventCategory': 'Trial',
                      'eventAction': '01-Trial-form-submitted',
                      'eventLabel': '-',
                      'nonInteraction': false
                    },
                    'eventCallback': function () {
                      window.dataLayer.push({ 'gaEventData': undefined });
                      // redirect to lobby
                      // setTimeout(function () { window.location.replace("https://lobby.easysoftware.com/trial/" + trialId + "?brand=easyredmine.com&lang=" + document.documentElement.lang.split("-")[0]); }, 1000);
                    }
                  });
                }
              }
            });
            // redirect to lobby
            // setTimeout(() =>
            //   window.location.replace(
            //     `https://lobby.easysoftware.com/trial/` +
            //     trialId +
            //     `?brand=easyredmine.com&lang=` +
            //     document.documentElement.lang.split("-")[0]
            //   ),
            //   4000
            // )
            return
          } else {
            setFormSubmitted(true)
            const redirectUrl =
              props?.formBuilderData?.puxFormOptions?.formRedirectUrl
            if (redirectUrl?.url[0]) {
              if (redirectUrl?.internal[0]) {
                window.location.href =
                  window.location.origin + `/` + redirectUrl.url[0]
              } else {
                window.location.href = redirectUrl.url
              }
              setLoading(false)
              return
            }
            setLoading(false)
            return
          }
        }
        setBeErrors(responseJson.errors)
      } else {
        setBeErrors([`Error submitting form. Please contact us.`])
      }

      if (recaptchaRef.current) {
        recaptchaRef.current.reset()
      }
      setLoading(false)
    } catch {
      setBeErrors([`Error submitting form. Please contact us.`])
      setLoading(false)
    }
  }

  return !formSubmitted ? (
    <>
      <FormProvider {...methods}>
        <form
          id={props.formBuilderData?.puxFormOptions.formID}
          className={loading ? `loading` : undefined}
          onSubmit={methods.handleSubmit(async (data) =>
            handleFormSubmit(data)
          )}
        >
          {mauticData ? buildMautic(mauticData, formBuilderData.length) : null}
          {formBuilderData.map((formField: FormFieldType, idx) =>
            buildForm(formField, idx)
          )}
        </form>
      </FormProvider>
      {props?.formBuilderData?.puxFormRecaptchaOptions
        ?.formValidateRecaptcha ? (
        <ReCAPTCHA
          theme='dark'
          size='invisible'
          ref={recaptchaRef}
          sitekey={
            props?.formBuilderData?.puxFormRecaptchaOptions.formRecaptchaSiteKey
          }
          style={{ display: `none` }}
        />
      ) : null}
      {beErrors?.length > 0 ? (
        <div className='alert alert-danger mb-20'>
          {beErrors?.map((error, i) => {
            return (
              <div key={i}>
                {error?.split(`: `)?.length > 0
                  ? error?.split(`: `)?.[1]
                  : (error as string)}
              </div>
            )
          })}
        </div>
      ) : undefined}
    </>
  ) : (
    <div className='g-recaptcha'>
      <div className='pux-form-success-text alert alert-success'>
        {props?.formBuilderData?.puxFormOptions?.formSuccessMessage}
      </div>
    </div>
  )
}

export default FormBuilder