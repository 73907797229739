import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { dt } from 'Root/Shared/utils/dynamicRS'

import { FormFieldInputType } from '../builders/FormBuilder'
import { WidgetProperitesPart } from '../builders/WidgetBuilder'
import FormErrorHelper from './FormErrorHelper'
import { getClass, getRequiredField, replaceResourceStrings } from './formFieldHelper'

const FormFieldInputWidget: React.FC<{
  props: FormFieldInputType
  widgetProperties?: WidgetProperitesPart
}> = ({ props, widgetProperties }) => {
  const stepable = [`number`, `date`, `datetime-local`, `time`]
  const multiple = [`email`, `file`]

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const formElement = props?.puxFormElement
  const formValidation = props?.puxFormElementValidation
  const formOptions = props?.puxFormElementOptions

  const getStepValue = (): number | undefined => {
    if (
      stepable.includes(props?.inputType) &&
      formOptions?.formElementOptionsStep?.toString() !== `1.0`
    ) {
      return formOptions?.formElementOptionsStep
    }
    return undefined
  }

  const isMultipleOptionsEnabled = (): boolean => {
    return (
      multiple.includes(props?.inputType) &&
      formOptions?.formElementOptionsMultiple
    )
  }

  const pattern = formValidation.formElementValidationPattern
    ? new RegExp(formValidation.formElementValidationPattern, ``)
    : new RegExp(`[sS]*`, ``)

  return (
    <div
      className={`FormGroup ${formOptions?.formElementOptionsCssClass ?? ``
        } ${getClass(props.metadata?.size, widgetProperties)} FormGroup--${props.inputType
        }Input`}
    >
      <label
        htmlFor={formElement?.formElementID}
      >
        {replaceResourceStrings(formElement?.formElementLabel)}
        {getRequiredField(
          props?.puxFormElementValidation?.formElementValidationRequired
        )}
      </label>
      <Controller
        name={formElement?.formElementName}
        defaultValue={replaceResourceStrings(formElement?.formElementDefaultValue) ?? undefined}
        control={control}
        rules={{
          required: formValidation.formElementValidationRequired
            ? dt(`PuxForm.Error.Required`)
            : false,
          min: {
            value: formValidation.formElementValidationMin ?? "",
            message: dt(`PuxForm.Error.Min.${props?.inputType ?? ``}`),
          },
          max: {
            value: formValidation.formElementValidationMax ?? "",
            message: dt(`PuxForm.Error.Max.${props?.inputType ?? ``}`),
          },
          minLength: {
            value: formValidation.formElementValidationMinLength ?? NaN,
            message: dt(`PuxForm.Error.Minlength.${props?.inputType ?? ``}`),
          },
          maxLength: {
            value: formValidation.formElementValidationMaxLength ?? NaN,
            message: dt(`PuxForm.Error.Maxlength.${props?.inputType ?? ``}`),
          },
          pattern: {
            value: pattern,
            message: dt(`PuxForm.Error.Pattern.${props?.inputType ?? ``}`),
          },
        }}
        render={({ field }) => (
          <>
            <input
              {...field}
              id={formElement?.formElementID}
              value={replaceResourceStrings(field.value)}
              type={props?.inputType}
              placeholder={replaceResourceStrings(formElement?.formElementPlaceholder)}
              autoFocus={formOptions?.formElementOptionsAutofocus}
              readOnly={formOptions?.formElementOptionsReadOnly}
              disabled={formOptions?.formElementOptionsDisabled}
              multiple={isMultipleOptionsEnabled()}
              autoComplete={
                formOptions?.formElementOptionsAutocomplete ? `on` : `off`
              }
              step={getStepValue()}
            />
          </>
        )}
      />
      <ErrorMessage
        render={(data) => <FormErrorHelper {...data} />}
        errors={errors}
        name={formElement?.formElementName ?? ``}
      />
    </div>
  )
}

export default FormFieldInputWidget
